import React from 'react'
import './GroupLogos.scss'

const GroupLogos = () => (
  <div className="group-logos">
    <div className="wrapper">
      <div className="group-parent">
        <a href="https://www.hifraser.com.au/about-us/our-companies/">
          <img
            src="/logos/hifrasergroup.svg"
            className="group-logo hifraser"
            alt="Parent company logo"
          />
        </a>
      </div>
      <a
        href="https://www.aquip.com.au"
        target="_blank"
        rel="noopener noreferrer"
        className="group-logo"
      >
        <img
          src="/logos/aquip.svg"
          className="aquip"
          alt="Child company logo"
        />
      </a>
      <a
        href="https://www.aquip.com.au"
        target="_blank"
        rel="noopener noreferrer"
        className="group-logo"
      >
        <img
          src="/logos/aquipflowservices.svg"
          className="aquipflowservices"
          alt="Child company logo"
        />
      </a>
      <a
        href="https://www.easternautomation.com.au"
        target="_blank"
        rel="noopener noreferrer"
        className="group-logo"
      >
        <img src="/logos/ea.svg" className="ea" alt="Child company logo" />
      </a>
      <a
        href="https://www.hifraser.com.au/"
        target="_blank"
        rel="noopener noreferrer"
        className="group-logo"
      >
        <img
          src="/logos/HiFraserBlue.svg"
          className="hif"
          alt="Child company logo"
        />
      </a>
      <a
        href="https://pipact.com.au"
        target="_blank"
        rel="noopener noreferrer"
        className="group-logo"
      >
        <img src="/logos/pac.svg" className="pac" alt="Child company logo" />
      </a>
      <a
        href="https://tidalfluidpower.com.au/"
        target="_blank"
        rel="noopener noreferrer"
        className="group-logo"
      >
        <img
          src="/logos/tfp_logo.svg"
          className="tfp"
          alt="Child company logo"
        />
      </a>
    </div>
  </div>
)

export default GroupLogos
