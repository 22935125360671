import React from 'react';
import Helmet from 'react-helmet';
import Header from './Header';
import Footer from './Footer';
import '../sass/global/styles.scss';

const Layout = ({ children, location, className = "" }) => (
  <div id="layout" className={className}>
    <Helmet title="Home | Koval" />
    <Header location={location} />
    <main>{children}</main>
    <Footer location={location} />
  </div>
)

export default Layout
