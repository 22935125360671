import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { decodeEntities } from '../utils/htmlParse';
// import './AcfLink.scss';

const AcfLink = ({ url, title, target, className }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);
  const linkTarget = target ? { target, rel: "noopener noreferrer" } : {};
  const isPageLink = url.startsWith('/') || url.startsWith(site.siteMetadata.siteUrl);
  if (isPageLink) {
    return (
      <Link
        to={url.replace(site.siteMetadata.siteUrl, '')}
        className={className}
        {...linkTarget}
      >
        {title ? decodeEntities(title) : 'Learn more'}
      </Link>
    );
  }
  return (
    <a
      href={url}
      className={className}
      {...linkTarget}
    >
      {title ? decodeEntities(title) : 'Learn more'}
    </a>
  );
}

export default AcfLink;
