import React, { useState } from 'react';
import AcfLink from './AcfLink';

export const HeaderNavigationMeganav = ({ items, itemClass, leftPos }) => {
  const [openNavItem, setOpenNavItem] = useState(null);
  const toggleNavItems = (index, item) => {
    if (item.children) {
      setOpenNavItem(index === openNavItem ? null : index);
    }
  }
  if (!items) return null;
  return (
    <div className={`header-navigation-meganav ${itemClass}`}>
      <div className="wrapper">
        {items.map((item, index) => {
          const itemClassNames = [
            `header-navigation-meganav-col`,
            `${item.classes}`,
            `${item.children ? 'parent' : ''}`,
            `${openNavItem === index ? 'open' : ''}`
          ];
          const marginLeft = itemClass !== 'services' ? {marginLeft: leftPos} : null;
          return (
            <div className={itemClassNames.join(' ')} style={marginLeft} key={index}>
              {/* eslint-disable-next-line */}
              <div className="link-wrapper" onClick={() => toggleNavItems(index, item)}>
                <AcfLink {...item} className="meganav-col-heading" />
              </div>
              {item.children && (
                <div className="header-navigation-subnav">
                  {item.children.map((itemlvl2, itemlvl2Index) => {
                    return <AcfLink className={itemlvl2.classes} {...itemlvl2} key={itemlvl2Index} />;
                  })}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
