import React, { useState } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import OnVisible from 'react-on-visible';
import { useStaticQuery, graphql, Link } from 'gatsby';
import HomeLogo from './HomeLogo';
import { decodeEntities } from '../utils/htmlParse';
import iso45001Accreditation from '../images/accreditations/iso45001.svg';
import iso14001Accreditation from '../images/accreditations/iso14001.svg';
import iso9001Accreditation from '../images/accreditations/iso9001.svg';
import './Footer.scss';

const Footer = ({location}) => {
  const [openLocations, setOpenLocations] = useState([0]);

  const toggleOpenLocations = (idx) => {
    const openLocationsIndex = openLocations.indexOf(idx);
    const openLocationsClone = [...openLocations];
    if (openLocationsIndex === -1) {
      openLocationsClone.push(idx);
    } else {
      openLocationsClone.splice(openLocationsIndex, 1);
    }
    setOpenLocations(openLocationsClone);
  }

  const isHome = location.pathname === '/';
  const DistlLink = () => (
    isHome ? (
      <a
        href="https://www.distl.com.au/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Distl
      </a>
    ) : 'Distl'
  );
  const backToTop = () => {
    scroll.scrollToTop();
  }
  const { wordpressAcfOptions } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          socialLinks {
            type
            url
          }
          locations {
            locationName
            addressStreet
            addressCity
            addressRegion
            addressPostCode
            phone
            email
          }
        }
      }
    }
  `);
  return (
    <OnVisible wrappingElement="footer">
      <div className="footer-top">
        <button type="button" className="back-to-top" onClick={backToTop} />
        <div className="wrapper">
          <div className="footer-top-column">
            <HomeLogo />
          </div>
          {wordpressAcfOptions.options.locations.length > 1 && (
            <div className="footer-top-column locations">
              {wordpressAcfOptions.options.locations.map((loc, index) => {
                const {
                  locationName = null,
                  addressStreet = null,
                  addressCity = null,
                  addressRegion = null,
                  addressPostCode = null,
                  phone,
                  email
                } = loc;
                const crp = [addressCity, addressRegion, addressPostCode];
                return (
                  <div className={`location ${openLocations.indexOf(index) >= 0 ? 'open' : ''}`} key={index}>
                    {locationName && (
                      // eslint-disable-next-line
                      <span
                        className="footer-heading location-heading"
                        onClick={() => toggleOpenLocations(index)}
                      >
                        {decodeEntities(locationName)}
                      </span>
                    )}
                    <div className="contact-details">
                      {addressStreet && (
                        <span>{decodeEntities(addressStreet)}</span>
                      )}
                      {crp.some(item => item !== undefined || item !== null) && (
                        <span>{crp.join(' ')}</span>
                      )}
                      {phone && (
                        <a
                          href={`tel:${phone}`}
                          className="location-phone"
                        >
                          {decodeEntities(phone)}
                        </a>
                      )}
                      {email && <a href={`mailto:${email}`}>{decodeEntities(email)}</a>}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className="footer-top-column">
            {wordpressAcfOptions.options.socialLinks && (
              <div className="follow-us-section">
                <span className="footer-heading follow-us-heading">Follow Us</span>
                {wordpressAcfOptions.options.socialLinks.map(link => {
                  return (
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      key={link.url}
                    >
                      {link.type}
                    </a>
                  );
                })}
              </div>
            )}
            <div className="accreditations">
              <span className="footer-heading">Accreditations</span>
              <img src={iso45001Accreditation} alt="Accreditation badge" />
              <img src={iso14001Accreditation} alt="Accreditation badge" />
              <img src={iso9001Accreditation} alt="Accreditation badge" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="wrapper">
          <div className="footer-bottom-column">
            <span className="copyright">
              {/* eslint-disable-next-line */}
              Copyright &copy; 2008–{new Date().getFullYear()} HIFraser
            </span>
          </div>
          <div className="footer-bottom-column">
            <div className="footer-bottom-links">
              <Link to="/terms/">Terms & Conditions</Link>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </div>
          </div>
          <div className="footer-bottom-column">
            <span className="credit">
              {/* eslint-disable-next-line */}
              Website by <DistlLink />
            </span>
          </div>
        </div>
      </div>
    </OnVisible>
  );
}

export default Footer;
