import React from 'react';
import { Link } from 'gatsby';
import './HomeLogo.scss';

const HomeLogo = () => (
  <Link to="/" className="home-logo">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 525.92 188.07">
      <path fill="#00355f" d="M135.44 104.51h44.97v17.23h-44.97v45.58h-19.97V59.69h71.19v17.99h-51.22v26.83zM207.09 91.09v10.83c2.29-4 7.31-13 24.39-12.5v18.74c-4 .16-10.83.3-16.47 4.58-7.77 5.94-7.92 13.71-7.92 26.37v28.21H189.4V91.09zM238.03 113.5c.3-3.81.6-7 3.05-11.43 7.32-13.26 25.45-13.26 30.32-13.26 7.32 0 16.16.91 22.87 4.87 9 5.49 9.14 12.66 9.14 20.89v40.4c0 5.79 0 7.77 3.36 12.34h-21.64c-.15-1.83-.45-4.88-.45-5.94a36 36 0 01-22.57 7.77c-17.22 0-27.59-10.67-27.59-23.77a23.21 23.21 0 012.44-10.38c5.49-11 17.23-13.41 24.54-14.78 15.7-3.2 16.92-3.51 23.63-5.64 0-3.81-.31-11.59-13-11.59-7.63 0-11.75 2.44-13.57 4.89-2 2.43-1.83 4.57-1.83 6.7zm47.1 15.86c-3.65 1.83-7.77 3.2-16 4.57-6.54.92-15.23 2.44-15.23 10.52 0 5.94 5 9 12.34 9 9.3 0 15.85-4.27 17.84-8.84a15.52 15.52 0 001.06-6.86zM324 144.75c4.58 5.64 11.44 9.76 22 9.76s15.09-5.18 15.09-9c0-4.88-5.49-6.1-7.93-6.56-2.59-.61-15.09-2.9-17.84-3.66-18.13-4.27-21.49-14.78-21.49-21.65 0-12.95 11.74-24.84 32.62-24.84 11.59 0 20 3.66 25.15 7.32a33.34 33.34 0 017.78 6.85l-13 12c-2.13-4.12-7.32-11.59-20.73-11.59-8.69 0-12.66 3.81-12.66 7.78 0 5.48 5.65 7 15.4 8.69 15.86 2.89 20.28 3.65 25.15 7.46a20.69 20.69 0 017.78 16.27c0 11.88-10.67 26.06-34.3 26.06-13.57 0-27-4.27-36.58-13.57zM461.96 150.24c-9.45 12.65-21.64 19.36-37.2 19.36-20 0-39.18-12.65-39.18-40.7 0-25.61 16.47-40.09 38-40.09 20.12 0 28.51 12 30 14.33 6.25 8.84 7.63 22.1 7.78 29.72h-56.4c2.13 12.05 9.6 18.9 21.64 18.9 12.5 0 17.54-7.32 19.82-10.51zm-20.73-30.8c-1.68-11.88-9-16-17.08-16-9.75 0-16.46 5.64-18.59 16zM487.4 91.09v10.83c2.28-4 7.31-13 24.38-12.5v18.74c-4 .16-10.82.3-16.47 4.58-7.77 5.94-7.91 13.71-7.91 26.37v28.21h-17.67V91.09zM33.99 59.69H0v107.63h34v-41.47h26v-27H34zm32.5 107.63h34.27V59.69H66.49z"/>
      <path d="M108.8 24.93A24.93 24.93 0 1183.86 0a24.93 24.93 0 0124.94 24.93" fill="#d31245"/>
      <path fill="none" d="M.07 0h525.85v188.07H.07z"/>
    </svg>
  </Link>
);

export default HomeLogo;
