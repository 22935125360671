import React, { useState, useEffect } from 'react';
import GatsbyLink from './GatsbyLink';
import HomeLogo from './HomeLogo';
import GroupLogos from './GroupLogos';
import { HeaderNavigation } from './HeaderNavigation';
import './Header.scss';

const Header = ({ location }) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const closeNav = () => {
    setNavOpen(false);
    document.body.classList.remove('nav-open');
  }

  const handleEscKey = (event) => {
    if (event.keyCode === 27) {
      closeNav();
    }
  }

  const handleHamburgerPress = () => {
    setNavOpen(!navOpen);
    if (document.body.classList.contains('nav-open')) {
      document.body.classList.remove('nav-open');
    } else {
      document.body.classList.add('nav-open');
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscKey, false);
    // Close nav on page change
    const { state: locationState } = location;
    if (locationState && locationState.prevPath !== location.pathname) {
      closeNav();
    }
  }, []);

  return (
    <header className={`main-header ${navOpen ? 'toggled' : ''} ${searchVisible ? 'searching' : ''}`}>
      <GroupLogos />
      <div className="main-header-container">
        <div className="wrapper">
          <button
            type="button"
            className={`hamburger ${navOpen ? 'toggled' : ''}`}
            onClick={handleHamburgerPress}
          >
            <span className="patty" />
          </button>
          <HomeLogo />
          <HeaderNavigation location={location} closeNav={closeNav} />
          <div className="search-toggle-container">
            <div className="search-links">
              <GatsbyLink className="button" to="/search/">Site Search</GatsbyLink>
              <GatsbyLink className="button" to="/products/search/">Products Search</GatsbyLink>
            </div>
            <button
              type="button"
              className="search-toggle"
              onClick={() => setSearchVisible(!searchVisible)}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
