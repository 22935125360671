import React, { useState, useRef, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import AcfLink from './AcfLink';
import { HeaderNavigationMeganav } from './HeaderNavigationMeganav';
// import { decodeEntities } from '../utils/htmlParse';
import './HeaderNavigation.scss';

export const HeaderNavigation = ({ location, closeNav }) => {
  const [openNavItem, setOpenNavItem] = useState(null);
  const { headerNav } = useStaticQuery(graphql`
    query headerNavQuery {
      headerNav: wordpressWpApiMenusMenusItems(slug: { eq: "header-navigation" }) {
        items {
          classes
          title
          target
          url
          children: wordpress_children {
            classes
            title
            target
            url
            children: wordpress_children {
              classes
              title
              target
              url
            }
          }
        }
      }
    }
  `);
  const toggleNavItems = (index, item) => {
    if (item.children) {
      setOpenNavItem(index === openNavItem ? null : index);
    }
  }
  if (!headerNav) return <nav className="header-navigation" />;
  return (
    <nav className="header-navigation">
      <div className="header-navigation-mobile-top">
        <button
          type="button"
          className="menu-close-button"
          onClick={closeNav}
        />
      </div>
      <div className={`header-navigation-items ${openNavItem !== null ? 'has-open' : ''}`}>
        {headerNav.items.map((item, index) => {
          const navItemClassNames = [
            `header-navigation-item`,
            `${item.classes}`,
            `${item.children ? 'parent' : ''}`,
            `${openNavItem === index ? 'open' : ''}`
          ];
          const navItemRef = useRef(null);
          const [offsetLeft, setOffsetLeft] = useState(null);
          const refreshOffsetLeft = () => {
            setOffsetLeft(navItemRef.current.offsetLeft - 32);
          }
          useEffect(() => {
            setOffsetLeft(navItemRef.current.offsetLeft - 32);
            const timeOut = setTimeout(refreshOffsetLeft, 1000);
            if (typeof window !== 'undefined') window.addEventListener('resize', refreshOffsetLeft);
            return () => {
              if (typeof window !== 'undefined') window.removeEventListener('resize', refreshOffsetLeft);
              clearTimeout(timeOut);
            }
          }, [navItemRef]);
          return (
            <div className={navItemClassNames.join(' ')} ref={navItemRef} key={index}>
              {/* eslint-disable-next-line */}
              <div className="link-wrapper top" onClick={() => toggleNavItems(index, item)}>
                <AcfLink {...item} />
              </div>
              {item.children && (
                <HeaderNavigationMeganav leftPos={offsetLeft} itemClass={item.classes} items={item.children} />
              )}
            </div>
          );
        })}
      </div>
    </nav>
  );
}
